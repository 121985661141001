<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div>
      <form-wizard
        color="#010638"
        :title="null"
        :subtitle="null"
        layout="vertical"
        finish-button-text="Cart"
        back-button-text="Previous"
        class="vertical-steps-2 steps-transparent mb-3"
        @on-complete="formSubmitted"
      >

        <wizard-step
          slot="step"
          slot-scope="props"
          :tab="props.tab"
          :transition="props.transition"
          :index="props.index"
        />
        <!-- account details tab -->
        <tab-content
          v-for="module in modules"
          :key="module.id"
          :title="module.name"
          class="grid-view ecommerce-application"
          :before-change="()=>validationForm(module.name)"
        >
          <validation-observer
            :ref="module.name"
            tag="form"
          >
            <!-- Products -->
            <section
              v-if="filterProduct(module.products).length > 0"
              class="grid-view-2"
            >
              <b-card
                v-for="product in filterProduct(module.products)"
                :key="product.id"
                class="ecommerce-card"
                no-body
              >
                <!--                <b-badge variant="danger" pill="true">4</b-badge>-->
                <div class="item-img text-center">
                  <b-img
                    :alt="`${product.name}-${product.id}`"
                    fluid
                    class="card-img-top"
                    :src="product.image_url"
                  />
                </div>

                <!-- Product Details -->
                <b-card-body
                  :class="product.isInCart ? `bg-secondary text-white` : null"
                >
                  <h6
                    :class="[`item-name`, product.isInCart ? `text-white` : null]"
                  >
                    {{ product.name }}
                  </h6>
                  <b-card-text class="item-description">
                    {{ product.description }}
                  </b-card-text>
                  <div v-if="product.maxQty > 1">
                    <b-card-text class="item-description">
                      <b-form-input
                        v-model="product.qty"
                        type="range"
                        :min="product.isInCart ? 1 : 0"
                        :max="product.maxQty"
                        @input="updatePrice"
                      />
                    </b-card-text>
                    <b-card-text class="w-100 item-right">
                      <validation-provider
                        #default="{ errors }"
                        :name="product.name"
                        :rules="{
                          required: product.isInCart || product.mandatory,
                          integer: true,
                          between:[product.isInCart ? 1 : 0,product.maxQty],
                        }"
                      >
                        <b-form-group
                          label-cols="12"
                          label-cols-lg="10"
                          label-size="lg"
                        >
                          <template slot="label">
                            {{ `Number of ${product.shortName}` }}
                          </template>
                          <b-form-input
                            :id="`input-${module.id}-${product.id}`"
                            v-model="product.qty"
                            type="number"
                            :state="errors.length > 0 ? false:null"
                            @input="updatePrice"
                          />
                        </b-form-group>
                        <small
                          :class="[`text-danger`, product.isInCart ? `text-lighten-3 font-weight-bold` : null]"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </b-card-text>
                  </div>
                  <h6
                    :class="[`item-price`, product.isInCart ? `text-white` : null]"
                  >
                    {{ product.price > 0 ? `IDR ${convertNumber(product.price * (product.maxQty > 1 ? product.qty : 1) * (([1, 2, 3, 25].includes(product.id)) ? 1 : qtyVisit))} / Month` : product.name === 'Periode' ? '' : 'FREE' }}
                  </h6>
                </b-card-body>

                <!-- Product Actions -->
                <div
                  v-if="!product.mandatory"
                  class="item-options text-center item-right"
                  :class="[`item-options`,`text-center`,`item-right`, product.isInCart ? `bg-secondary` : null]"
                >
                  <b-button
                    variant="primary"
                    tag="a"
                    class="w-50"
                    @click="handleCartActionClick(module,product)"
                  >
                    <feather-icon
                      icon="ShoppingCartIcon"
                      class="mr-50"
                    />
                    <span>{{ product.isInCart ? 'Remove from Cart' : 'Add to Cart' }}</span>
                  </b-button>
                </div>
              </b-card>
            </section>
            <section
              v-else
              class="grid-view-2"
            >
              <h4>No Available Data, please choose one at tab before</h4>
            </section>
          </validation-observer>
        </tab-content>
      </form-wizard>

    </div>
  </b-overlay>
</template>

<script>
import { FormWizard, WizardStep, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow, BCol, BFormGroup, BFormInput, BCard, BCardBody, BLink, BImg, BCardText, BButton, BOverlay, BBadge,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, between, integer } from '@validations'
import { makeToast, numberWithCommas, updatePrice } from '@/@fake-db/utils'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import { useShopRemoteData } from './useECommerceShop'
import { codeIconInfo } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // BSV
    FormWizard,
    TabContent,
    WizardStep,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BLink,
    BBadge,
    BImg,
    BCardText,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      selectedContry: 'select_value',
      selectedLanguage: 'nothing_selected',
      codeIconInfo,
      required,
      between,
      integer,
      modules: {},
      tax: {},
      showLoading: false,
    }
  },
  computed: {
    qtyVisit() {
      return this.modules.find(e => e.name === 'Coverage').products.find(e => e.id === 1).qty ?? 1
    }
  },
  created() {
    this.showLoading = true
    this.$swal({
      title: 'Welcome to Jarvist Lite !',
      html: `<br /><div style="text-align: justify">
Utilizing Jarvist Lite System for Medium enterprise to solve your In-store visibility issues.
Quick, Easy and Affordable.
<br />
<br />
Let Your Business Become Handy with Us !
<br />
<br />
Please kindly fill and choose our Ready-to-Use Modules below according to your company's needs.
</div>`,
      timer: 3000,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    })
    useJwt.jwtGet('console/modules')
      .then(response => {
        this.showLoading = false
        this.modules = response.data.data.modules
        this.tax = response.data.data.tax

        const userCart = JSON.parse(localStorage.getItem('modulesCart'))
        this.modules.forEach(module => {
          module.products.forEach(product => {
            /* eslint-disable no-param-reassign */
            product.isInCart = product.mandatory
            product.qty = product.mandatory ? 1 : 0
            product.isVisible = true
            if (userCart) {
              product.isInCart = userCart.find(e => e.id === module.id).products.find(e => e.id === product.id)?.isInCart ?? product.mandatory
              product.qty = userCart.find(e => e.id === module.id).products.find(e => e.id === product.id)?.qty ?? (product.mandatory ? 1 : 0)
            }
            /* eslint-enable */
          })
        })
        localStorage.setItem('moduleTax', JSON.stringify(this.tax))
        localStorage.setItem('modulesCart', JSON.stringify(this.modules))
        updatePrice(this)
      }).catch(error => {
        this.showLoading = false
        makeToast(this, error.message, 'AlertTriangleIcon', 'danger')
      })
  },
  methods: {
    updatePrice() {
      return updatePrice(this)
    },
    filterProduct(products) {
      return products.filter(e => e.isVisible ?? true)
    },
    handleCartActionClick(module, product) {
      this.modules.find(e => e.id === module.id).products.find(e => e.id === product.id).isInCart = !this.modules.find(e => e.id === module.id).products.find(e => e.id === product.id).isInCart
      const prod = this.modules.find(e => e.id === module.id).products.find(e => e.id === product.id)
      if ((prod.isInCart && prod.maxQty === 1) || (prod.isInCart && prod.qty == 0)) {
        this.modules.find(e => e.id === module.id).products.find(e => e.id === product.id).qty = 1
      }
      updatePrice(this)
    },
    convertNumber(x) {
      return numberWithCommas(x)
    },

    validationForm(refs) {
      return new Promise((resolve, reject) => {
        this.$refs[refs][0].validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    formSubmitted() {
      if (store.state['app-ecommerce'].cartModulesCount === 0) return makeToast(this, 'Please input at least 1 item', 'AlertTriangleIcon', 'danger')
      localStorage.setItem('modulesCart', JSON.stringify(this.modules))
      this.$router.push({ name: 'personal-info' })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
